.event-detail {
    .event-desc-left {
        .event-desc-img {
            margin-bottom: 40px;
            @media (max-width: 1200px) {
                margin-bottom: calc(12px + (35 - 12) * ((100vw - 280px) / (1200 - 280)));
            }
            img {
                // object-fit: cover;
                width: 100%;
                @media (min-width: 992px) {
                    height: 500px;
                }
            }
        }
        .event-desc-detail {
            h2 {
                font-family: var(--secondary-font);
                margin: 0;
                color: #222;
                font-size: 40px;
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 20px;
                @media (max-width: 1200px) {
                    font-size: calc(20px + (35 - 20) * ((100vw - 280px) / (1200 - 280)));
                    margin-bottom: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                }
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 30px;
                letter-spacing: 0.02em;
                color: #3b3b3b;
                @media (max-width: 1200px) {
                    font-size: calc(13px + (15 - 13) * ((100vw - 280px) / (1200 - 280)));
                    margin-bottom: calc(12px + (25 - 12) * ((100vw - 280px) / (1200 - 280)));
                }
            }
        }
        .event-srevice {
            margin-bottom: 50px;
            margin-bottom: calc(12px + (30 - 12) * ((100vw - 280px) / (1200 - 280)));
            .event-service-main-title {
                ul {
                    li {
                        font-size: calc(13px + (15 - 13) * ((100vw - 280px) / (1200 - 280)));
                        margin-bottom: 5px;
                    }
                }
            }
            h3 {
                margin: 0;
                color: #222;
                font-size: 40px;
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 20px;
            }
            .event-service-main-title {
                ul {
                    li {
                        svg {
                            margin-right: 10px;
                            path {
                                fill: var(--mainColor);
                            }
                        }
                    }
                }
            }
        }
        .specification {
            .section-title {
                h3 {
                    margin: 0;
                    font-family: var(--secondary-font);
                    color: #222;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 1.2;
                    margin-bottom: 20px;
                    @media (max-width: 1200px) {
                        font-size: calc(20px + (35 - 20) * ((100vw - 280px) / (1200 - 280)));
                        margin-bottom: calc(12px + (18 - 12) * ((100vw - 280px) / (1200 - 280)));
                    }
                }
            }
            .rantal-table {
                border-collapse: collapse;
                border-spacing: 0;
                // width: 100%;
                margin-bottom: 20px;
                td {
                    padding: 16px 12px;
                    vertical-align: top;
                    @media (max-width: 1200px) {
                        padding: calc(10px + (12 - 10) * ((100vw - 280px) / (1200 - 280)));
                    }
                }
                tbody {
                    tr:nth-of-type(odd) {
                        background: #f8f8f8;
                        border-top: 1px solid #e5e5e5;
                        border-bottom: 1px solid #e5e5e5;
                    }
                }
            }
        }
    }
    .event-btn {
        padding: 0 10px;
        font-size: 18px;
        background-color: var(--mainColor);
        display: block;
        text-align: center;
        line-height: 2.88;
        width: 100%;
        font-weight: 500;
        border: none;
        margin-bottom: 0;
        color: #fff;
        text-transform: capitalize;
        .icon {
            margin-right: 5px;
            svg {
                vertical-align: sub;
            }
        }
    }
}