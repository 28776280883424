.history-area {
  .history-timeline {
    position: relative;
    ::after {
      content: "";
      position: absolute;
      background: var(--mainColor);
      width: 2px;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  h3 {
    font-size: 25px;
    font-family: var(--secondary-font);
    @media (max-width: 1024px) {
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1024 - 280)));
    }
  }
  .history-left-content {
    .history-content {
      margin-right: 40px;
      padding: 40px;
      text-align: right;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  .history-right-content {
    .history-content {
      margin-left: 40px;
      padding: 40px;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  .history-content {
    span {
      font-weight: 500;
      color: var(--mainColor);
      margin: 5px 0;
      display: block;
    }
    p {
      font-size: 16px;
      color: #333;
      line-height: 1.5;
      @media (max-width: 1024px) {
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1024 - 280)));
      }
    }
  }
  .history-icon {
    position: absolute;
    background: var(--mainColor);
    color: #fff;
    font-size: 20px;
    width: 25px;
    height: 25px;
    line-height: 0px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .image-wrap {
    img {
      @media (min-width: 768px) {
        height: 230px;
        width: 230px;
      }
    }
    .left-image {
      margin-right: 40px;
      padding: 40px;
      text-align: right;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
    .right-image {
      margin-left: 40px;
      padding: 40px;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  .content-wrapper {
    margin: auto;
  }
}