
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden;
  position: relative;
  color: #000;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: var(--mainColor);
}
ul,
li {
  list-style: none;
}
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
.clearfix {
  clear: both;
}
:root {
  --primary-font: "Roboto", sans-serif;
  --secondary-font: "Merriweather", serif;
  --mainColor: #ED682D;
  --mainColorOpacity: #f0f0f0;
  --mainColorbg: #f5f5f5;
  --bodyColor: #666666;
  --font-color: #1e1e1f;
  --theme-color-primary: #ffffff;
  --btn-font-size: 16px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px
}
.mb-55 {
  margin-bottom: 55px
}
@media screen and (max-width:991px) {
  .mb-50 {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 575px) {
  .mb-50 {
    margin-bottom: 30px;
  }
}
::selection {
  color: var(--theme-color-primary);
  background-color: var(--mainColor);
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--mainColor);
}
@media (min-width: 1600px) {
  .container {
    width: 1600px;
    max-width: 100%
  }
}
@media (max-width: 1600px) {
  .container {
    max-width: 100%;
    padding: 0 45px
  }
}
@media (max-width: 1023px) {
  .container {
    padding: 0 15px
  }
}
label {
  &.required::after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}
.left-btn {
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(0, 0);
  background: 0 0;
  height: 30px;
  width: 30px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
}
.right-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  background: 0 0;
  height: 30px;
  width: 30px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
}
.slick-slider {
  .slick-dots {
    margin: 15px;
    width: calc(100% - 30px);
    text-align: center;
    display: flex !important;
    position: unset;
    li {
      display: inline-block;
      /* padding: 1px; */
      cursor: pointer;
      height: 2.5px;
      width: 100%;
      position: relative;
      margin: 0;
      list-style: none;
      background: rgba(0, 0, 0, 0.234);
      left: 0;
      top: 0;
      &.slick-active {
        background: var(--mainColor);
      }
      button {
        display: none;
        ::before {
          content: '' !important;
        }
      }
    }
  }
  .slick-slide {
    img {
      display: inline-block;
    }
  }
}
.default-theme-btn {
  padding: 15px 35px;
  text-align: center;
  color: var(--theme-color-primary);
  font-size: var(--btn-font-size);
  font-weight: 400;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: none;
  position: relative;
  /* z-index: 0; */
  background: var(--mainColor);
  text-transform: capitalize;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  border-radius: 50px;
  white-space: nowrap;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #333;
    color: #fff;
  }
  @media only screen and (max-width: 1600px) {
    padding: calc(8px + (15 - 8) * ((100vw - 280px) / (1600 - 280))) calc(18px + (30 - 18) * ((100vw - 280px) / (1600 - 280)));
  }
  a {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color-primary);
    letter-spacing: 0.9px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    @media only screen and (max-width: 1600px) {
      font-size: calc(13px + (15 - 13) * ((100vw - 280px) / (1600 - 280)));
      line-height: normal;
    }
  }
}
.title-section {
  text-align: center;
  margin-bottom: 40px;
  .sub-title {
    span {
      color: var(--mainColor);
      font-weight: 600;
      display: block;
      text-transform: capitalize;
      margin-bottom: 7px;
      line-height: 1.5;
      font-size: 18px;
      @media only screen and (max-width: 1600px) {
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
      }
    }
  }
  .main-title {
    h2 {
      font-size: 40px;
      font-family: var(--secondary-font);
      margin-top: 0;
      line-height: 1.2;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: 700;
      @media screen and (max-width: 1200px) {
        font-size: calc(15px + (35 - 15) * ((100vw - 280px) / (1200 - 280)));
      }
    }
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: calc(20px + (40 - 20) * ((100vw - 280px) / (1200 - 280)));
  }
}