.get-involved-section-wrapper {
  .get-involved-area {
    .aapinment-detail {
      .title-section {
        text-align: left;
        margin-bottom: 15px;
      }
    }
    .involved-section-wrapper {
      margin: auto;
      .title-section {
        text-align: left;
      }
      @media screen and (max-width:991px) {
        margin-top: calc(15px + (30 - 15) * ((100vw - 280px) / (991 - 280)));
      }
      .involved-form-group {
        .title-label {
          display: block;
        }
        .form-group {
          margin-bottom: 20px;
          label {
            margin-bottom: 5px;
            text-transform: capitalize;
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              vertical-align: sub;
              cursor: pointer;
              accent-color: var(--mainColor);
              margin-right: 10px;
            }
          }
          .form-control {
            height: 60px;
            color: var(--bodyColor);
            border: 1px solid #eeeeee;
            background-color: #fff;
            font-size: 15px;
            padding: 10px 20px;
            width: 100%;
            border-radius: 0;
            &.involved-message {
              height: auto;
            }
          }
          .form-select {
            &:focus {
              outline: none;
              border: none;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
          select {
            height: 60px;
            color: var(--bodyColor);
            border: 1px solid #eeeeee;
            background-color: transparent;
            font-size: 15px;
            outline: none;
            position: relative;
            padding: 9px 20px 9px 10px;
            background-color: #fff;
            border: 1px solid #e4e4e4;
            appearance: none;
            width: 100% !important;
            background-position: right 10px center;
            background-repeat: no-repeat;
            background-size: auto 50%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23212121'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='1'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
          }
        }
        .checkbox-items {
          label {
            margin: 0 15px 0 0;
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
    .btn-wrapper {
      margin-top: 20px;
      @media (max-width: 992px) {
     margin-top: 0;
    }
      .default-theme-btn {
        font-weight: 600;
        font-size: 18px;
        padding: 15px 50px;
      }
    }
  }
}