.mobile-menu {
    z-index: 99;
    background-color: var(--theme-color-primary);
    width: 100%;
    transition: all linear 0.2s;
    .mobile-menu-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        transition-duration: 0.3s;
        visibility: hidden;
        opacity: 0;
        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    .mobile-menu-main {
        width: 320px;
        height: 100%;
        background-color: var(--theme-color-primary);
        position: fixed;
        top: 0;
        right: -320px;
        z-index: 99;
        overflow-y: scroll;
        transition-duration: 0.3s;
        &.active {
            right: 0 !important;
        }
        .logo {
            padding: 15px 20px;
            position: relative;
            img {
                // width: 166px;
                height: 45px;
                object-fit: contain;
            }
            .logo-text {
                margin-left: 8px;
                font-size: 18px;
                font-weight: 700;
                @media (max-width: 1024px) {
                    font-size: calc(13px + (18 - 13) * ((100vw - 280px) / (1024 - 280)));
                }
            }
        }
        .close-mobile-menu {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto;
            height: max-content;
            i {
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                color: var(--font-color);
                font-size: 20px;
                cursor: pointer;
                transition: var(--transision);
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
        .contact-list {
            position: relative;
            padding: 30px 20px 20px;
            li {
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 20px;
                .icon {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    line-height: 54px;
                    border-radius: 50%;
                    background-color: var(--mainColor);
                    color: var(--theme-color-primary);
                    margin-right: 15px;
                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }
                .title {
                    display: block;
                    font-size: 18px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                }
                .text {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    color: var(--font-color);
                    a {
                        color: var(--font-color);
                        transition: all 300ms ease;
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
    .mobile-menu-body {
        padding-top: 10px;
        ul {
            li {
                a {
                    font-weight: 500;
                    color: var(--font-color);
                    border-top: 1px solid rgb(135 126 126 / 36%);
                    width: 100%;
                    padding: 0px 20px;
                    line-height: 40px;
                    padding-right: 10px;
                    transition: all linear 0.2s;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:last-child {
                        // border-bottom: 1px solid rgb(135 126 126 / 36%);
                    }
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        .menu-list {
            i {
                width: 32px;
                height: 32px;
                background-color: var(--theme-color-primary);
                text-align: center;
                line-height: 32px;
                font-size: 12px;
                border-radius: 3px;
                background-color: var(--theme-color-primary);
                transition: all linear 0.2s;
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--theme-color-primary);
                }
            }
            ul {
                li {
                    ul {
                        padding-left: 0;
                        display: none;
                        a {
                            padding: 0px 20px;
                            padding-left: 40px;
                        }
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .social-icon {
        padding: 20px;
        text-align: center;
        border-top: 1px solid rgb(135 126 126 / 36%);
        ul {
            li {
                display: inline-block;
                margin: 0 4px;
                a {
                    width: 36px;
                    height: 36px;
                    color: var(--font-color);
                    text-align: center;
                    display: inline-block;
                    line-height: 36px;
                    border-radius: 3px;
                    border: 1px solid var(--bodyColor);
                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--theme-color-primary);
                        border-color: var(--mainColor);
                    }
                }
            }
        }
    }
    .mobile-topbar {
        width: 100%;
        background-color: var(--theme-color-primary);
        box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        .bars {
            i {
                width: 40px;
                height: 25px;
                background-color: transparent;
                color: var(--font-color);
                font-size: 25px;
                text-align: center;
                line-height: 1;
                border-radius: 3px;
                cursor: pointer;
                transition: var(--transision);
            }
        }
        .logo {
            img {
                width: 104px;
                height: 80px;
                object-fit: contain;
                @media (max-width: 991px) {
                    height: 50px;
                    width: auto;
                }
            }
            .logo-text {
                margin-left: 8px;
                font-size: 22px;
                font-weight: 700;
                @media (max-width: 1024px) {
                    font-size: calc(13px + (20 - 13) * ((100vw - 280px) / (1024 - 280)));
                }
            }
        }
    }
    .peta-menu {
        margin-left: 15px;
    }
}