.message-president {
  .message-image{
    @media (max-width: 991px) {
      text-align: center;
    }
  }
  .message-contain-section {
    .title-section {
      margin-bottom: 25px;
      text-align: left;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media screen and (max-width: 1200px) {
        margin-bottom: calc(15px + (25 - 15) * ((100vw - 280px) / (1200 - 280)));
      }
    }
    .message-desc {
      margin-bottom: 30px;
      @media only screen and (max-width: 1600px) {
        margin-bottom: calc(10px + (28 - 10) * ((100vw - 280px) / (1600 - 280)));
      }
      p {
        font-size: 17px;
        padding-bottom: 15px;
        margin-bottom: 0;
        @media screen and (max-width: 1200px) {
          font-size: calc(14px + (17 - 14) * ((100vw - 280px) / (1200 - 280)));
        }
        @media only screen and (min-width: 992px) {
          max-width: 650px;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      text-align: center;
      margin-top: calc(15px + (30 - 15) * ((100vw - 280px) / (991 - 280)));
    }
  }
}