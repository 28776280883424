.footer-main {
  position: relative;
  font-size: 15px;
  text-align: center;
  overflow: hidden;
  padding: 50px 0;
  background: #f9f9f9;
  @media (max-width: 991px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 575px) {
    padding: 30px 0;
  }
  ul {
    list-style: none;
    li {
      color: #fff;
    }
  }
  p {
    color: #fff;
    margin-bottom: 0;
  }
  .footer-image{
    a{
      img{
        @media (max-width: 992px) {
          height: 60px;
          width: auto;
      }
      }
    }
  }
  .footer-link {
    margin: 30px 0;
  @media (max-width: 991px) {
    margin: 15px 0;
  }
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      @media (max-width: 767px) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      li {
        padding: 0 20px;
        position: relative;
        @media (max-width: 767px) {
          padding: 0 10px;
        }
        :first-child {
          &::before {
            display: none;
          }
        }
        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background: var(--mainColor);
          content: "";
          border-radius: 50%;
          @media (max-width: 767px) {
            left: -2px;
          }
        }
        a {
          font-weight: 400;
          font-size: 18px;
          line-height: 1.444;
          text-transform: capitalize;
          color: #525252;
          &:hover {
            color: var(--mainColor);
          }
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      li:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .link-widget {
    overflow: hidden;
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      li {
        position: relative;
        margin-left: 15px;
        &:first-child {
          margin: 0;
        }
        a {
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 35px;
          background: #f9f9f9;
          border: 1px var(--mainColor) solid;
          color: #0f1a20;
          font-size: 15px;
          border-radius: 50%;
          &:hover {
            background: var(--mainColor);
            color: #fff;
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 50px;
    @media (max-width: 991px) {
      margin-top: 15px;
    }
    p {
      color: #000;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      a {
        text-decoration: underline;
        color: #000;
        &:hover {
          color: var(--mainColor);
        }
      }
    }
  }
}