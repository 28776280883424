#header-fixed-height {
    &.active-height {
        display: block;
        height: 100px;
    }
}
.header {
    background-color: var(--theme-color-primary);
    width: 100%;
    z-index: 99;
    position: relative;
    transition: transform 0.1s ease-in-out;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: -webkit-transform 0.1s ease-in-out;
    &.sticky-menu {
        position: fixed;
        left: 0;
        margin: auto;
        bottom: auto;
        right: 0;
        top: 0;
        width: 100%;
        background-color: var(--theme-color-primary);
        box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
        border-radius: 0;
        animation: slideupbottom 0.40s ease-out;
    }
    .navbar-nav {
        .nav-item {
            position: relative;
            &:hover {
                a {
                    color: var(--mainColor);
                }
            }
            .nav-link {
                color: var(--font-color);
                font-weight: 500;
                font-size: 20px;
                margin-right: 24px;
                line-height: 1.5;
                @media (max-width: 1500px) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (1500 - 280)));
                }
                &.active {
                    color: var(--mainColor);
                }
                i {
                    font-size: 10px;
                }
            }
            .sub-menu {
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 230px;
                background-color: var(--theme-color-primary);
                box-shadow: 0px 10px 30px 0px rgba(137, 139, 142, 0.2);
                padding: 18px 0;
                transition: all 0.3s ease-in-out;
                visibility: hidden;
                opacity: 0;
                margin-top: 20px;
                li {
                    position: relative;
                    .peta-menu-icon {
                        position: absolute;
                        right: 18px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    a {
                        color: var(--font-color);
                        font-weight: 500;
                        line-height: 38px;
                        padding: 0 20px;
                        width: 100%;
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                    /* Styles for petaMenu */
                    .peta-menu {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        min-width: 230px;
                        background-color: var(--theme-color-primary);
                        box-shadow: 0px 10px 30px 0px rgba(137, 139, 142, 0.2);
                        padding: 18px 0;
                        transition: all 0.3s ease-in-out;
                        visibility: hidden;
                        opacity: 0;
                        margin-top: 20px;
                        li {
                            a {
                                color: var(--font-color);
                                font-weight: 500;
                                line-height: 38px;
                                padding: 0 20px;
                                width: 100%;
                                &:hover {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }
                    &:hover>.peta-menu {
                        margin-top: 0;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            &:hover>.sub-menu {
                margin-top: 0;
                visibility: visible;
                opacity: 1;
            }
        }
        .nav-link {
            padding: 0;
        }
    }
    .header-right-info {
        .header-btn {
            font-weight: 500;
        }
        .social {
            ul {
                li {
                    display: inline-block;
                    a {
                        font-size: 15px;
                        color: var(--font-color);
                        margin-left: 25px;
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
        }
        .help-number {
            &:hover {
                .icon {
                    background-color: var(--mainColor);
                    color: var(--theme-color-primary);
                }
            }
            .icon {
                width: 55px;
                height: 55px;
                text-align: center;
                line-height: 55px;
                border-radius: 50%;
                background-color: var(--mainColor);
                color: var(--theme-color-primary);
                margin-right: 15px;
                transition: var(--transision);
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
        }
        .info {
            p {
                font-size: 18px;
                font-weight: 500;
                color: var(--font-color);
            }
            h6 {
                font-weight: 700;
                font-size: 20px;
                &:hover {
                    a {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
}
@keyframes slideupbottom {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}