.our-causes-area {
  .our-causes-timeline {
    position: relative;
  }
  .our-causes-left-content {
    .our-causes-content {
      margin-right: 40px;
      padding: 40px;
      text-align: left;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  .our-causes-right-content {
    .our-causes-content {
      margin-left: 40px;
      padding: 40px;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  .our-causes-content {
    span {
      font-weight: 500;
      color: var(--mainColor);
      margin: 5px 0;
      display: block;
    }
    p {
      font-size: 18px;
      color: #333;
      @media (max-width: 1024px) {
        font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1024 - 280)));
    }
    }
  }
  .image-wrap {
    img {
      @media (min-width: 768px) {
      height: 400px;
      }
    }
    .left-image {
      margin-right: 40px;
      padding: 40px;
      text-align: right;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
    .right-image {
      margin-left: 40px;
      padding: 40px;
      @media (max-width: 991px) {
        margin: 0 0 25px 0;
        text-align: unset;
        padding: 0px;
      }
    }
  }
  h4 {
    font-size: 80px;
    color: #ed682d7d;
    font-weight: 700;
    margin-bottom: 15px;
    @media (max-width: 1024px) {
      font-size: calc(35px + (70 - 35) * ((100vw - 280px) / (1024 - 280)));
  }
  }
  h3 {
    font-size: 24px;
    font-family: var(--secondary-font);
    margin-bottom: 10px;
    font-weight: 600;
  }
  .content-wrapper {
    margin: auto;
  }
}