.gallery-main-wrapper {
  .no-data{
    margin: 25px 0;
    text-align: center;
  }
  .gallery-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    button {
      text-decoration: none;
      padding: 10px 20px;
      margin: 0 10px;
      color: #333;
      font-weight: 600;
      border-radius: 5px;
      transition: background-color 0.3s, color 0.3s;
      background: #fff;
      border: none;
      outline: none;
      @media (max-width: 992px) {
        padding: 5px 20px;
      }
      &:hover {
        background-color: #fff;
        color: #000;
      }
      &.active {
        background-color: var(--mainColor);
        color: #fff;
      }
    }
  }
  .gallery-item {
    position: relative;
    margin-bottom: 15px;
    padding: 15px 15px 0 15px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(4, 23, 26, 0.07);
    
    .image {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .zoom-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: max-content;
        z-index: 8;
        opacity: 0;
        width: max-content;
        margin: auto;
        transition: all 0.6s ease-in-out 0s;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        svg {
          height: 50px;
          width: 50px;
          color: #fff;
          text-align: center;
          outline-offset: 6px;
          padding: 14px;
          border-radius: 50px;
          margin: 0 5px;
          background-color: var(--mainColor);
          position: relative;
          cursor: pointer;
        }
      }
      img {
        border-radius: 5px;
        -moz-transition: all 400ms ease-out 0s;
        -webkit-transition: all 400ms ease-out 0s;
        -ms-transition: all 400ms ease-out 0s;
        -o-transition: all 400ms ease-out 0s;
        transition: all 400ms ease-out 0s;
      }
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: -100px;
        bottom: 0;
        opacity: 0;
        background-color: rgba(0, 0, 0, .5);
        transition: all 0.6s ease-in-out 0s;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s
      }
    }
    .gallery-desc {
      display: block;
      margin-bottom: 30px;
      padding: 15px 0;
      position: relative;
      @media (max-width: 992px) {
        margin-bottom: 15px;
      }
      .gallery-title {
        font-size: 20px;
        @media (max-width: 1024px) {
          font-size: calc(18px + (18 - 18) * ((100vw - 280px) / (1024 - 280)));
        }
      }
      .date {
        padding-bottom: 15px;
        font-size: 19px;
        font-weight: 500;
        line-height: 1;
        color: var(--mainColor);
      }
    }
    &:hover {
      .zoom-icon {
        opacity: 1;
      }
      .image {
        img {
          transform: scale(1.1);
        }
      }
      .image {
        &::after {
          opacity: 1;
          top: 0;
        }
      }
    }
  }
}