.brand-section-wrapper {
  .brand-section {
    .brand-box-wrapper {
      margin: 0 -15px;
      .brand-list-slider {
        .brand-main-item {
          padding: 0 15px;
          @media (max-width: 991px) {
            padding: 0 7.5px;
          }
          .brand-item {
            background-color: var(--theme-color-primary);
            padding: 8px 20px;
            border: 1px solid #d4d4d4;
            text-align: center;
            .brand-icon {
              img {
                transition: all 300ms ease;
                -webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -ms-transition: all 300ms ease;
                -o-transition: all 300ms ease;
              }
            }
            :hover {
              img {
                animation: swing 0.8s linear;
                -webkit-transition: swing 0.8s linear;
                -moz-transition: swing 0.8s linear;
                -ms-transition: swing 0.8s linear;
                -o-transition: swing 0.8s linear;
                transition: all 300ms ease;
                -webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -ms-transition: all 300ms ease;
                -o-transition: all 300ms ease;
              }
            }
          }
        }
        .left-btn {
          left: 0px;
          top: 45%;
        }
        .right-btn {
          right: 0px;
          top: 45%;
        }
      }
    }
  }
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg)
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg)
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg)
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg)
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg)
  }
}