.counter-area {
    position: relative;
    .counter-wrap {
        overflow: hidden;
        padding: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        position: relative;
        background: var(--theme-color-primary);
        box-shadow: 0px 14px 41px #00000015;
        @media (max-width: 767px) {
            padding: 25px 15px;
            justify-content: space-between;
        }
        .counter-item {
            display: flex;
            align-items: center;
            gap: 20px;
            position: relative;
            .content {
                img {
                    height: 80px;
                    width: 80px;
                    @media (max-width: 575px) {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
            h3 {
                font-size: 36px;
                text-align: center;
                color: #000;
                font-weight: 700;
                line-height: 1.38;
                @media screen and (max-width: 1200px) {
                    font-size: calc(20px + (32 - 20) * ((100vw - 280px) / (1200 - 280)));
                  }
            }
            p {
                color: #000;
                @media screen and (max-width: 1200px) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1200 - 280)));
                  }
            }
            .content {
                text-align: center;
            }
        }
    }
}