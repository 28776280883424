.testimonial-section-wrapper {
  .testimonial-section {
      .testimoanial-slider {
        margin: 0 -15px;
        .testimoanial-main-item {
          padding: 0 15px;
          .testimoanial-item {
            text-align: left;
            // box-shadow: 0 5px 10px 0 rgba(23,84,116,.18);
            background-color: var(--theme-color-primary);
            border: 1px solid #C8C8C8;
            padding: 20px;
            margin-bottom: 5px;
            .testimoanial-box {
              .testimoanial-content {
                .testimoanial-title {
                  font-size: 18px;
                  line-height: 1.22;
                  margin-bottom: 8px;
                  font-family: var(--secondary-font);
                  text-transform: capitalize;
                  color: var(--mainColor);
                  @media only screen and (max-width: 1600px) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
                    margin-bottom: calc(5px + (8 - 5) * ((100vw - 280px) / (1600 - 280)));
                  }
                }
                .testimoanial-designation {
                  font-size: 12px;
                  line-height: 1.25;
                  letter-spacing: 0.6px;
                  margin-bottom: 0;
                  text-transform: uppercase;
                  font-weight: 500;
                }
              }
            }
            .testimoanial-desc {
              font-size: 18px;
              line-height: 1.66;
              margin: 0;
              margin: 0 0 20px;
              overflow: hidden;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              @media only screen and (max-width: 1600px) {
                font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
              }
            }
          }
        }
      }
  }
}