.our-team {
    .team-members {
        display: grid;
        justify-content: center;
        gap: 30px;
        grid-template-columns: repeat(5, 1fr);
        @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 650px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
        }
        @media screen and (max-width: 575px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 350px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .team-member {
            background-color: #fff;
            //   border-radius: 8px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            padding: 20px;
            text-align: center;
            @media (max-width: 991px) {
                padding: 15px 10px;
            }
            .team-photo {
                object-fit: cover;
                margin-bottom: 20px;
                @media (min-width: 991px) {
                    width: 200px;
                    height: 200px;
                    }
            }
            .team-name {
                font-size: 18px;
                margin-bottom: 5px;
                @media (max-width: 1024px) {
                    font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1024 - 280)));
                }
            }
            .team-role {
                font-size: 14px;
                line-height: 1;
                margin-bottom: 10px;
                color: #000000;
                @media (max-width: 1024px) {
                    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1024 - 280)));
                }
            }
            .team-description {
                font-size: 14px;
                line-height: 1.5;
                color: #0c0c0c;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                @media (max-width: 1024px) {
                    font-size: calc(11px + (14 - 11) * ((100vw - 280px) / (1024 - 280)));
                }
            }
        }
    }
}