.desktop-top-header {
    background-color: var(--mainColor);
    text-align: center;
    @media (max-width: 992px) {
        display: none;
    }
    .head-wrap {
        display: flex;
        align-items: center;
    }
    .header-nav-offer-text {
        padding: 10px 0;
        font-size: 15px;
        line-height: normal;
        font-weight: 400;
        letter-spacing: 0.06em;
        text-align: left;
        text-transform: capitalize;
        color: #fff;
        margin-bottom: 0;
        svg {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
        p {
            display: inline-block;
            margin-bottom: 0;
            font-size: 15px;
            line-height: normal;
            font-weight: 400;
            letter-spacing: 0.06em;
            text-transform: capitalize;
            color: #fff;
            @media (max-width: 1300px) {
                font-size: 13px;
            }
        }
    }
    .icon-wrapper {
        .social-icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a {
                margin-left: 10px;
                svg {
                    color: #fff;
                }
            }
        }
    }
}