.our-impact {
  .container {
    .logo {
      text-align: center;
    }
    .impact-title {
      margin: 30px 0 20px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 25px;
      font-family: var(--secondary-font);
      @media (max-width: 1024px) {
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1024 - 280)));
    }
    }
    .impact-description {
      color: #222222;
      line-height: 1.6;
      li {
        list-style: unset;
        margin-bottom: 10px;
        font-size: 18px;
        @media (max-width: 1024px) {
          font-size: calc(13px + (16 - 13) * ((100vw - 280px) / (1024 - 280)));
      }
      }
    }
  }
}