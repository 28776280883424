.contact-section-wrapper {
  .contact-area {
    iframe{
      background-color: #e4e4e4;
    }
    .contact-detail {
      .title-section {
        text-align: left;
        margin-bottom: 15px;
      }
      .contact-details {
        .desc {
          margin-bottom: 20px;
          @media (max-width: 1024px) {
            line-height: 1.5;
            font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1024 - 280)));
        }
        }
        .location-detail {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          @media (max-width: 991px) {
           margin-bottom: 10px;
          }
          .icon {
            margin-right: 10px;
            display: inline-block;
            min-width: 35px;
            min-height: 35px;
            width: 35px;
            height: 35px;
            font-size: 16px;
            box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
            background-color: #fff;
            border-radius: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          a,
          span {
            display: inline-block;
            vertical-align: super;
            font-size: 17px;
            word-break: break-all;
            @media (max-width: 1024px) {
              line-height: 1.5;
              font-size: calc(14px + (17 - 14) * ((100vw - 280px) / (1024 - 280)));
          }
          }
        }
      }
    }
    .contact-section-wrapper {
      .title-section {
        text-align: left;
      }
      @media screen and (max-width:991px) {
        margin-top: calc(15px + (30 - 15) * ((100vw - 280px) / (991 - 280)));
      }
      .contact-form-group {
        label {
          margin-bottom: 5px;
          text-transform: capitalize;
        }
        .form-group {
          margin-bottom: 20px;
          .form-control {
            height: 60px;
            color: var(--bodyColor);
            border: 1px solid #eeeeee;
            background-color: transparent;
            font-size: 15px;
            padding: 10px 20px;
            width: 100%;
            border-radius: 0;
            &.contact-message {
              height: auto;
            }
          }
          .form-select {
            &:focus {
              outline: none;
              border: none;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
        }
      }
    }
    .default-theme-btn {
      font-weight: 600;
      font-size: 18px;
      padding: 15px 50px;
    }
  }
}