.event-section-wrapper {
  .event-section {
    .event-slider {
      .event-main-item {
        .event-item {
          position: relative;
          display: block;
          border-radius: 15px;
          padding: 15px 15px 0 15px;
          background-color: rgb(255, 255, 255);
          box-shadow: 0px 0px 50px 0px rgba(4, 23, 26, 0.07);
          margin-bottom: 30px;
          @media (max-width: 992px) {
         margin-bottom: 15px;
        }
          .event-box {
            overflow: hidden;
            .event-img {
              overflow: hidden;
              border-radius: 5px;
              img {
                -moz-transition: all 400ms ease-out 0s;
                -webkit-transition: all 400ms ease-out 0s;
                -ms-transition: all 400ms ease-out 0s;
                -o-transition: all 400ms ease-out 0s;
                transition: all 400ms ease-out 0s;
              }
            }
            &:hover {
              img {
                transform: scale(1.1);
              }
            }
            .event-content-main {
              padding: 33px 0;
              position: relative;
              .date-wrap {
                position: absolute;
                top: -27px;
                right: 0;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                background-color: var(--mainColor);
                color: #fff;
                line-height: 1.2;
                padding: 15px 15px;
                border-radius: 7px 0 0 7px;
                @media (max-width: 1024px) {
               padding: 12px;
              }
              }
            }
          }
        }
      }
    }
    .event-content {
      .event-content-top {
        .location,
        .time {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1em;
          position: relative;
          font-weight: 500;
          color: #000;
          display: flex;
          align-items: center;
        }
        .event-contact {
          .icon {
            display: inline-block;
            svg {
              path {
                fill: #000;
              }
            }
          }
          .contact-no {
            font-weight: 600;
            display: inline-block;
            cursor: pointer;
          }
        }
        .contact {
          font-size: 16px;
          margin-top: 10px;
          text-transform: capitalize;
          a {
            font-size: 15px;
            word-break: break-all;
          }
        }
        .default-theme-btn {
          margin-top: 15px;
          padding: 10px 35px;
        }
        svg {
          margin-right: 7px;
          path {
            fill: #000;
          }
        }
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          margin: 10px 0;
          font-size: 18px;
          line-height: 1.5;
          position: relative;
          color: #1a1a1a;
        }
        h4 {
          margin: 10px 0;
          font-size: 22px;
          line-height: 1.4;
          font-weight: 700;
      font-family: var(--secondary-font);
          color: var(--font-color);
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          @media (max-width: 1440px) {
            font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1440 - 280)));
        }
        }
      }
    }
  }
}