.know-sec {
  .container {
    .image {
      text-align: center;
      img{
        @media (min-width: 1200px) {
          height: 400px;
          width: 100%;
      }
      }
    }
    .know-title {
      margin: 30px 0 20px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 25px;
      font-family: var(--secondary-font);
      @media (max-width: 1024px) {
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1024 - 280)));
    }
    }
    .know-description {
      color: #222222;
      line-height: 1.6;
      li {
        list-style: unset;
        margin-bottom: 10px;
        font-size: 20px;
        @media (max-width: 1024px) {
          font-size: calc(15px + (18 - 15) * ((100vw - 280px) / (1024 - 280)));
      }
      }
    }
  }
  .regard{
    margin-top: 20px;
    span{
      font-size: 22px;
    }
  }
  .regard-name{
    font-size: 21px;
    margin-top: 5px;
  }
}