.bread-crumb-bar {
    background-color: #f9f9f9;
    padding: 15px 0;
    margin-bottom: 30px;
    text-align: center;
    // @media (max-width: 991px) {
    //     padding: 20px;
    // }
    @media (max-width: 480px) {
        padding: 10px;
    }
    .breadcrumb-list {
        h2 {
            color: #1B1B1B;
            display: inline-block;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 10px;
            text-transform: capitalize;
            display: none;
            @media (max-width: 991px) {
                font-size: calc(15px + (28 - 15) * ((100vw - 280px) / (1200 - 280)));
            }
        }
        .page-breadcrumb {
            ol {
                display: flex;
                margin: 0;
                align-items: center;
                justify-content: center;
                li {
                    font-size: 14px;
                    line-height: normal;
                    color: #1B1B1B;
                    font-weight: 400;
                    padding: 0;
                    text-transform: capitalize;
                    svg {
                        vertical-align: bottom;
                    }
                }
            }
            .breadcrumb-item+.breadcrumb-item:before {
                color: #6B7385;
                font-size: 14px;
                content: "/";
                font-weight: 600;
                line-height: 1.5;
                font-family: "Font Awesome 5 Free";
                padding-right: 16px;
                margin: 0 15px 0;
                padding: 0;
                background: none;
            }
        }
    }
}