.about-us {
  .title-desc {
    margin-bottom: 15px;
    @media only screen and (max-width: 1600px) {
      margin-bottom: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
    }
    span {
      font-weight: 500;
      color: var(--mainColor);
      font-size: 19px;
      line-height: 1.2;
      display: inline-block;
      @media (max-width: 1024px) {
        font-size: calc(12px + (19 - 12) * ((100vw - 280px) / (1024 - 280)));
      }
    }
  }
  .aboutus-section-wrapper {
    .about-us-contain-section {
      .title-section {
        margin-bottom: 25px;
        text-align: left;
        @media only screen and (max-width: 991px) {
          text-align: center;
        }
        @media screen and (max-width: 1200px) {
          margin-bottom: calc(15px + (25 - 15) * ((100vw - 280px) / (1200 - 280)));
        }
      }
      .abou-us-desc {
        margin-bottom: 15px;
        @media only screen and (max-width: 1600px) {
          margin-bottom: calc(10px + (28 - 10) * ((100vw - 280px) / (1600 - 280)));
        }
        p {
          font-size: 17px;
          padding-bottom: 15px;
          margin-bottom: 0;
          @media screen and (max-width: 1200px) {
            font-size: calc(16px + (17 - 16) * ((100vw - 280px) / (1200 - 280)));
          }
          @media only screen and (min-width: 992px) {
            max-width: 650px;
          }
        }
      }
      @media only screen and (max-width: 991px) {
        text-align: center;
        margin-top: calc(15px + (30 - 15) * ((100vw - 280px) / (991 - 280)));
      }
    }
    .about-us-box {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      @media only screen and (max-width: 1600px) {
        margin-bottom: calc(10px + (28 - 10) * ((100vw - 280px) / (1600 - 280)));
      }
      .about-us-icon {
        margin-right: 20px;
        @media (max-width: 992px) {
          margin-right: 10px;
        }
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          @media only screen and (max-width: 991px) {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
      .about-us-content {
        text-align: left;
        .about-us-title {
          font-size: 16px;
          line-height: 1.22;
          font-family: var(--secondary-font);
          text-transform: capitalize;
          color: var(--mainColor);
          @media only screen and (max-width: 1600px) {
            font-size: calc(11px + (16 - 11) * ((100vw - 280px) / (1600 - 280)));
          }
        }
        .about-us-designation {
          font-size: 18px;
          line-height: 1.25;
          letter-spacing: 0.6px;
          margin-bottom: 8px;
          font-weight: 400;
          @media only screen and (max-width: 1600px) {
            font-size: calc(12px + (18 - 12) * ((100vw - 280px) / (1600 - 280)));
            margin-bottom: calc(5px + (8 - 5) * ((100vw - 280px) / (1600 - 280)));
          }
        }
      }
    }
  }
}