.main-slider {
  position: relative;
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-slider {
    .slick-dots {
      display: block;
      position: absolute;
      bottom: 2%;
      left: 0;
      right: 0;
      justify-content: center;
      z-index: 1;
      margin-top: 0;
      width: max-content;
      margin: auto;
      @media (max-width: 767px) {
        bottom: 2%;
        margin-bottom: 5px;
      }
      @media (max-width: 480px) {
        display: none;
      }
      li {
        background-color: #ed682d90;
        height: 7px;
        border-radius: 50px;
        width: 38px;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        -ms-transition: all 0.35s ease-in-out;
        -o-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out;
        margin: 0 10px;
        display: inline-block;
        @media (max-width: 767px) {
          width: 20px;
          margin: 0 5px;
        }
        &:hover,
        &.slick-active {
          background: var(--mainColor);
          height: 7px;
          width: 85px;
          -webkit-transition: all 0.35s ease-in-out;
          -moz-transition: all 0.35s ease-in-out;
          -ms-transition: all 0.35s ease-in-out;
          -o-transition: all 0.35s ease-in-out;
          transition: all 0.35s ease-in-out;
          @media (max-width: 767px) {
            width: 40px;
          }
        }
        button {
          display: none;
          ::before {
            content: '' !important;
          }
        }
      }
    }
    .slick-slide {
      img {
        width: 100%;
        display: inline-block;
        object-fit: cover;
      }
    }
  }
}